<template>
	<div>
		<main-header></main-header>
		<bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs>
		<h1 class="page-title">문의하기</h1>
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
import BreadCrumbs from '@/components/common/BreadCrumbs.vue';

export default {
	components: {
		Footer,
		MainHeader,
		BreadCrumbs,
	},
	data() {
		return {
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '문의하기', link: '/inquiry' },
			],
		};
	},
	methods: {
		selected(crumb) {
			console.log(crumb);
			this.$router.push(crumb.link);
		},
		chkStep(i) {
			this.step = i;
		},
	},
};
</script>

<style></style>
